import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PercentIcon from '@mui/icons-material/Percent';
import { Box, Typography } from '@mui/material';
import * as yup from 'yup';

import { AlarmDto, UserDto, UserService } from '@/_generatedApi';
import Button from '@/components/ui/common/button/CButton';
import Checkbox from '@/components/ui/fields/Checkbox';
import Input from '@/components/ui/fields/Input';
import { Option } from '@/components/ui/fields/types';
import Field from '@/components/ui/form/Field';
import Form from '@/components/ui/form/Form';
import { MAX_TAKE_ITEMS } from '@/constants/pagination';
import { useShowToast } from '@/hooks/use-show-toast';
import { ElectricityAlarmsContext } from '@/pages/electricity-alarms-page/ElectricityAlarmsPage';
import { phoneSchema } from '@/utils/validation';

import Select from '../ui/fields/Select';

const validationSchema = yup.object({
  odometerId: yup.number().required('validation.required'),
  value: yup
    .number()
    .min(0, 'validation.zeroOrMore')
    .max(100, 'validation.hundredOrLess')
    .required('validation.required'),
  usersId: yup.array().of(yup.number()).min(1, 'validation.required'),
  externalEmail: yup.string().email().required('validation.required'),
  externalPhone: phoneSchema,
  sendByPhone: yup.boolean(),
  sendByEmail: yup.boolean(),
});

export type ElectricityAlarmFormProps = {
  alarm: AlarmDto | null;
  onSubmit: (
    values: ElectricityAlarmFormOutputValues,
    id?: number | null
  ) => void;
  onCancel: () => void;
};

export type ElectricityAlarmFormInputValues = {
  odometerId: number | null;
  value: number | null;
  usersId: number[];
  externalEmail: string | null;
  externalPhone: string | null;
  sendByPhone: boolean | null;
  sendByEmail: boolean | null;
};

export type ElectricityAlarmFormOutputValues = yup.InferType<
  typeof validationSchema
>;

const ElectricityAlarmForm: FC<ElectricityAlarmFormProps> = ({
  alarm,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation();
  const odometers = useContext(ElectricityAlarmsContext);
  const dialogType = alarm ? 'update' : 'add';

  const { showGenericErrorToast } = useShowToast();

  const [users, setUsers] = useState<Option[]>([]);

  const initialValues: ElectricityAlarmFormInputValues = {
    odometerId: alarm?.odometer?.id || null,
    value: Number(alarm?.value) || null,
    usersId: alarm?.users?.map((user) => user.id || 0) || [],
    externalEmail: alarm?.externalEmail || null,
    externalPhone: alarm?.externalPhone || null,
    sendByPhone: alarm?.sendByPhone || false,
    sendByEmail: alarm?.sendByEmail || false,
  };

  const fetchUsers = useCallback(async () => {
    try {
      const users = await UserService.getUserList({
        take: MAX_TAKE_ITEMS,
        skip: 0,
      });

      const mappedUsersToSelect = users.data?.map((item): Option => {
        const user = item as UserDto;
        return {
          value: user.id || 0,
          label: `${user.firstName} ${user.lastName}` || 0,
        };
      });

      if (mappedUsersToSelect?.length) {
        setUsers(mappedUsersToSelect);
      }
    } catch (e) {
      showGenericErrorToast();
    }
  }, [showGenericErrorToast]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleSubmit = async (values: ElectricityAlarmFormInputValues) => {
    onSubmit(
      values as ElectricityAlarmFormOutputValues,
      alarm ? alarm.id : undefined
    );
  };

  return (
    <Box
      sx={{
        maxWidth: '400px',
        width: '100%',
        display: 'flex',
        margin: 'auto',
        flexDirection: 'column',
        paddingY: '24px',
      }}
    >
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, resetForm }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Field<string>
              name="odometerId"
              render={(props) => (
                <Select
                  autoFocus
                  options={odometers || []}
                  label={t('electricityPage.alarmPage.odometer')}
                  {...props}
                  required
                  data-cy-field="odometer"
                  clearable
                />
              )}
            />
            <Field<number>
              name="value"
              render={(props) => (
                <Input
                  type="number"
                  label={t('electricityPage.alarmPage.value')}
                  {...props}
                  required
                  endIcon={<PercentIcon />}
                  data-cy-field="value"
                />
              )}
            />
            <Field<string>
              name="usersId"
              render={(props) => (
                <Select
                  options={users}
                  label={t('electricityPage.alarmPage.users')}
                  {...props}
                  required
                  data-cy-field="odometer"
                  multiple
                  clearable
                />
              )}
            />
            <Box>
              <Typography variant="h6">
                {t('electricityPage.alarmPage.notify')}
              </Typography>
              <Field<boolean>
                name="sendByPhone"
                render={(props) => (
                  <Checkbox
                    label={t('electricityPage.alarmPage.sendByPhone')}
                    {...props}
                    data-cy-field="sendByPhone"
                  />
                )}
              />
              <Field<boolean>
                name="sendByEmail"
                render={(props) => (
                  <Checkbox
                    label={t('electricityPage.alarmPage.sendByEmail')}
                    {...props}
                    data-cy-field="sendByEmail"
                  />
                )}
              />
            </Box>
            <Field<string>
              name="externalEmail"
              render={(props) => (
                <Input
                  label={t('electricityPage.alarmPage.externalEmail')}
                  {...props}
                  required
                  data-cy-field="externalEmail"
                />
              )}
            />
            <Field<string>
              name="externalPhone"
              render={(props) => (
                <Input
                  label={t('electricityPage.alarmPage.externalPhone')}
                  {...props}
                  required
                  data-cy-field="externalPhone"
                />
              )}
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Button
                type="button"
                size="large"
                data-cy-button="cancel"
                onClick={() => {
                  resetForm();
                  onCancel();
                }}
                onMouseDown={(e) => e.preventDefault()}
              />
              <Button
                disableElevation
                type="submit"
                size="large"
                disabled={isSubmitting}
                data-cy-button="submit"
                variant={dialogType}
                onMouseDown={(e) => e.preventDefault()}
              />
            </Box>
          </Box>
        )}
      </Form>
    </Box>
  );
};

export default ElectricityAlarmForm;
